import React, { Component } from "react";
/* global google */
//import { compose } from "redux";
import { connect } from "react-redux";
import { compose, withProps, lifecycle } from "recompose";
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  DirectionsRenderer,
} from "react-google-maps"

const MapWithADirectionsRenderer = compose(
  withProps({
    googleMapURL: "https://maps.googleapis.com/maps/api/js?key=AIzaSyCtLmADN8hhPSf4lNO3PlwUAyL6w9cJOGU&v=3.exp&libraries=geometry,drawing,places",
    loadingElement: <div style={{ height: `100%`}} />,
    containerElement: <div style={{ height: `100%`}} />,
    mapElement: <div style={{ height: `100%`}} />,
  }),
  withScriptjs,
  withGoogleMap,
  lifecycle({
    componentDidMount() {
      const DirectionsService = new google.maps.DirectionsService();

      DirectionsService.route({
        origin: new google.maps.LatLng(this.props.info.location.latitude, this.props.info.location.longitude),
        destination: new google.maps.LatLng(this.props.lat, this.props.lon),
        travelMode: google.maps.TravelMode.DRIVING,
      }, (result, status) => {
        if (status === google.maps.DirectionsStatus.OK) {
          this.setState({
            directions: result,
          });
        } else {
          console.error(`error fetching directions ${result}`);
        }
      });
    }
  })
)(props =>
  (<GoogleMap
    defaultZoom={10}
    defaultCenter={new google.maps.LatLng(41.8507300, -87.6512600)}
  >
    {props.directions && <DirectionsRenderer directions={props.directions} />}
  </GoogleMap>)
);

class MapContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      longitude: 0,
      latitude: 0
    };
  }
  componentDidMount() {
    navigator.geolocation.getCurrentPosition(position => {
      this.setState({
        longitude: position.coords.longitude,
        latitude: position.coords.latitude
      });
    });
  }
  render() {
    const { info } = this.props;
    return (
      <div style={{ height: "100vh", width: "100%" }}>
        <MapWithADirectionsRenderer lat={this.state.latitude} lon={this.state.longitude} info={info}/>
      </div>

    );
  }
}

const mapStateToProps = state => {
  return {
    info: state.reducer.info
  };
};

export default connect(mapStateToProps)(MapContainer);
