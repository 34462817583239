import React from "react";
import { connect } from "react-redux";

class Sidebar extends React.Component {
  render() {
    const { info } = this.props;
console.log(info.user.picture);
    return (
      <div
        style={{
          padding: 10,
          backgroundColor: "#1b1e34"
        }}
      >
        <img
          src={`${info.user.picture}`}
          alt={"Avatar"}
          width={"100%"}
          height={"100%"}
        />
        <div
          style={{
            color: "#ffffff",
            textAlign: "center",
            justifyContent: "center",
            fontSize: 28,
            fontFamily: "Poppins-Regular",
            marginTop: 20
          }}
        >
          {info.user.fullname} tiene una emergencia. su ubicación exacta está en
          el mapa.
        </div>
        <div
          style={{
            color: "#ffffff",
            textAlign: "center",
            justifyContent: "center",
            fontSize: 28,
            fontFamily: "Poppins-Regular",
            marginTop: 20
          }}
        >
          Su tipo de sangre es {info.user.typeBlood}.
        </div>
        <div
          style={{
            color: "#ffffff",
            textAlign: "center",
            justifyContent: "center",
            fontSize: 28,
            fontFamily: "Poppins-Regular",
            marginTop: 20
          }}
        >
          Y es alérgico a:
        </div>
        {info.user.alergy.Alergies && info.user.alergy.Alergies.map((item, i) =>    <div key={i}
            style={{
              color: "#ffffff",
              textAlign: "center",
              justifyContent: "center",
              fontSize: 28,
              fontFamily: "Poppins-Regular",
              marginTop: 5
            }}
          >
            {item.name.es}
          </div>)}

      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    info: state.reducer.info
  };
};

export default connect(mapStateToProps)(Sidebar);
